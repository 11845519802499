import {action, configure, makeObservable, observable} from 'mobx';
import React from "react";
import {isDesktop} from "@/Helpers/Function";


export default class CollectionsStore {
    // Управляем svg иконкой
    @observable videoMinimaSvg = true;
    @observable videoStorySvg = true;
    @observable videoWishSvg = true;
    @observable videoWishPlaySvg = false;
    @observable videoWishVolumeSvg = true;
    @observable videoNorthernLightsSvg = true;
    @observable videoNorthernLightsPlaySvg = false;
    @observable videoNorthernLightsMainPlayPauseFlag = true;
    @observable videoNorthernLightsPlayPauseFlag = true;
    @observable videoNorthernLightsVolumeSvg = true;


    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }

    // Главный слайдер коллекции I WISH
    wishDataSliderMain = [
        {
            poster: '/storage/img/collections/wish/banner_video_cover.jpg',
            urlVideo: '/storage/img/collections/wish/',
            urlWish: '/catalog/wish',
            img: '/storage/img/collections/wish/slider-main-img_1.png',
            alt: 'Img',
            btnText: 'Смотреть украшения',
        },
    ].map((n, i) => ({...n, id: i + 1}));

    // Слайдер коллекции I WISH
    wishDataSliderSet = [
        {
            title: 'Пожелания любви',
            description: 'Украшения «I wish», которые желают своим владелицам Любви – очень романтичные и нежные.' +
                ' В их силуэтах нашли своё отражения символы верной и чистой любви, совершенства, лёгкости, красоты,' +
                ' гармонии. Ведь как бы часто ни говорили мы о любви, её никогда не бывает слишком',
            urlSet: '/catalog/product/03-3216-00-201-1111-76',
            img: '/storage/img/collections/wish/carevna-lebed.jpg',
            imgMob: '/storage/img/collections/wish/carevna-lebed-mob.jpg',
            iconTheme: '/storage/img/collections/wish/icon-2.png',
            imgText: '«Царевна Лебедь»',
            btnText: 'Посмотреть сет',
        },
        {
            title: 'Пожелания любви',
            description: 'Украшения «I wish», которые желают своим владелицам Любви – очень романтичные и нежные.' +
                ' В их силуэтах нашли своё отражения символы верной и чистой любви, совершенства, лёгкости, красоты,' +
                ' гармонии. Ведь как бы часто ни говорили мы о любви, её никогда не бывает слишком',
            urlSet: '/catalog/product/03-3214-00-201-1111-76',
            img: '/storage/img/collections/wish/kolibri.jpg',
            imgMob: '/storage/img/collections/wish/kolibri-mob.jpg',
            iconTheme: '/storage/img/collections/wish/icon-2.png',
            imgText: '«Колибри»',
            btnText: 'Посмотреть сет',
        },
        {
            title: 'Пожелания любви',
            description: 'Украшения «I wish», которые желают своим владелицам Любви – очень романтичные и нежные.' +
                ' В их силуэтах нашли своё отражения символы верной и чистой любви, совершенства, лёгкости, красоты,' +
                ' гармонии. Ведь как бы часто ни говорили мы о любви, её никогда не бывает слишком',
            urlSet: '/catalog/product/03-3223-00-264-1111-76',
            img: '/storage/img/collections/wish/zolotoj-lotos.jpg',
            imgMob: '/storage/img/collections/wish/zolotoj-lotos-mob.jpg',
            iconTheme: '/storage/img/collections/wish/icon-2.png',
            imgText: '«Золотой Лотос»',
            btnText: 'Посмотреть сет',
        },
        {
            title: 'Пожелания Счастья',
            description: 'Пожелания Счастья в коллекции «I wish» призваны вселять уверенность и оптимизм в свою ' +
                'владелицу. Их дизайн символизирует возрождение, новую жизнь, гармонию небесного и земного. ' +
                'Такой подарок может стать важным моментом в отношениях. А может стать физическим воплощением ' +
                'личного стремления к идеалу.',
            urlSet: '/catalog/product/03-3215-00-201-1111-75',
            img: '/storage/img/collections/wish/vernyj-sputnik.jpg',
            imgMob: '/storage/img/collections/wish/vernyj-sputnik-mob.jpg',
            iconTheme: '/storage/img/collections/wish/icon-1.png',
            imgText: '«Верный спутник»',
            btnText: 'Посмотреть сет',
        },
        {
            title: 'Пожелания здоровья',
            description: 'Украшения-обереги или пожелания Здоровья из коллекции «I wish» дарят позитивное ' +
                'настроение и чувство гармонии. В этих изделий можно встретить символы долголетия, возрождения, ' +
                'защиты, радости. Они воплощают проявление заботы о близком человеке, и одновременно являются ' +
                'напоминанием о том, что на самом деле важно…',
            urlSet: '/catalog/product/03-3219-00-000-1111-74',
            img: '/storage/img/collections/wish/tainstvennyj-drakon.jpg',
            imgMob: '/storage/img/collections/wish/tainstvennyj-drakon-mob.jpg',
            iconTheme: '/storage/img/collections/wish/icon-4.png',
            imgText: '«Таинственный дракон»',
            btnText: 'Посмотреть сет',
        },
        {
            title: 'Пожелания здоровья',
            description: 'Украшения-обереги или пожелания Здоровья из коллекции «I wish» дарят позитивное ' +
                'настроение и чувство гармонии. В этих изделий можно встретить символы долголетия, возрождения, ' +
                'защиты, радости. Они воплощают проявление заботы о близком человеке, и одновременно являются ' +
                'напоминанием о том, что на самом деле важно… ',
            urlSet: '/catalog/product/03-3222-00-201-1111-76',
            img: '/storage/img/collections/wish/krylya-babochki.jpg',
            imgMob: '/storage/img/collections/wish/krylya-babochki-mob.jpg',
            iconTheme: '/storage/img/collections/wish/icon-4.png',
            imgText: '«Крылья бабочки»',
            btnText: 'Посмотреть сет',
        },
        {
            title: 'Пожелания удачи',
            description: 'Украшения-пожелания Удачи из коллекции «I wish» придают владелице веры в собственные ' +
                'силы и вселяют надежду на самое лучшее. Силуэты этих изделий включают образы удачи, искренности, ' +
                'исполнения желаний, а также символы защиты, гармонии и победы. Чтобы удача всегда была рядом… ',
            urlSet: '/catalog/product/03-3217-00-264-1111-75',
            img: '/storage/img/collections/wish/dragocennyj-sad.jpg',
            imgMob: '/storage/img/collections/wish/dragocennyj-sad-mob.jpg',
            iconTheme: '/storage/img/collections/wish/icon-3.png',
            imgText: '«Драгоценный сад»',
            btnText: 'Посмотреть сет',
        },
        {
            title: 'Пожелания удачи',
            description: 'Украшения-пожелания Удачи из коллекции «I wish» придают владелице веры в собственные ' +
                'силы и вселяют надежду на самое лучшее. Силуэты этих изделий включают образы удачи, искренности, ' +
                'исполнения желаний, а также символы защиты, гармонии и победы. Чтобы удача всегда была рядом… ',
            urlSet: '/catalog/product/03-3224-00-204-1111-75',
            img: '/storage/img/collections/wish/obgonyaya-vremya.jpg',
            imgMob: '/storage/img/collections/wish/obgonyaya-vremya-mob.jpg',
            iconTheme: '/storage/img/collections/wish/icon-3.png',
            imgText: '«Обгоняя время»',
            btnText: 'Посмотреть сет',
        },
        {
            title: 'Пожелания удачи',
            description: 'Украшения-пожелания Удачи из коллекции «I wish» придают владелице веры в собственные ' +
                'силы и вселяют надежду на самое лучшее. Силуэты этих изделий включают образы удачи, искренности, ' +
                'исполнения желаний, а также символы защиты, гармонии и победы. Чтобы удача всегда была рядом… ',
            urlSet: '/catalog/product/03-3218-00-202-1111-75',
            img: '/storage/img/collections/wish/lovec-snov.jpg',
            imgMob: '/storage/img/collections/wish/lovec-snov-mob.jpg',
            iconTheme: '/storage/img/collections/wish/icon-3.png',
            imgText: '«Ловец снов»',
            btnText: 'Посмотреть сет',
        },
    ].map((n, i) => ({...n, id: i + 1}));

    // Массив призов в слайдере для участников
    minimaData = [
        {
            imgPath: '/storage/img/collections/minima/slider/1.png',
        },
        {
            imgPath: '/storage/img/collections/minima/slider/2.png',
        },
        {
            imgPath: '/storage/img/collections/minima/slider/3.png',
        },
        {
            imgPath: '/storage/img/collections/minima/slider/4.png',
        },
        {
            imgPath: '/storage/img/collections/minima/slider/5.png',
        },
        {
            imgPath: '/storage/img/collections/minima/slider/6.png',
        },
    ].map((n, i) => ({...n, id: i + 1}));

    //Массив призов в слайдере для участников
    storyData = [
        {
            imgPath: '/storage/img/collections/skazka/block6/1.png',
        },
        {
            imgPath: '/storage/img/collections/skazka/block6/2.png',
        },
        {
            imgPath: '/storage/img/collections/skazka/block6/3.png',
        },
        {
            imgPath: '/storage/img/collections/skazka/block6/4.png',
        },
        {
            imgPath: '/storage/img/collections/skazka/block6/5.png',
        },
        {
            imgPath: '/storage/img/collections/skazka/block6/6.png',
        },
    ].map((n, i) => ({...n, id: i + 1}));

    // Слайдер шаги
    minimaStepsData = [
        {
            imgPath: '/storage/img/collections/minima/stepsslider/mobile icon-1.png',
            text: 'Отсканируй QR-код',
        },
        {
            imgPath: '/storage/img/collections/minima/stepsslider/mobile icon-2-1.png',
            text: 'Заполни анкету',
        },
        {
            imgPath: '/storage/img/collections/minima/stepsslider/mobile icon-3.png',
            text: 'Участвуй в розыгрыше!',
        },
    ].map((n, i) => ({...n, id: i + 1}));


    /**
     * Воспроизведение видео коллекция MINIMA
     */
    @action mediaMinima = () => {
        try {
            const video = document.getElementById("video");

            if (video.paused) {
                video.play();
                this.videoMinimaSvg = false;
            } else {
                video.pause();
                this.videoMinimaSvg = true;
            }
            video.onended = (e) => {
                this.videoMinimaSvg = true;
            };
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Воспроизведение видео коллекция Живая сказка
     */
    @action mediaStory = () => {
        try {
            const video = document.getElementById("video");

            if (video.paused) {
                video.play();
                this.videoStorySvg = false;
            } else {
                video.pause();
                this.videoStorySvg = true;
            }
            video.onended = (e) => {
                this.videoStorySvg = true;
            };
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Воспроизведение видео коллекция I WISH
     */
    @action mediaWish = () => {
        try {
            const video = document.getElementById("video_wish");

            if (video.paused) {
                video.play();
                this.videoWishSvg = false;
            } else {
                video.pause();
                this.videoWishSvg = true;
            }
            video.onended = (e) => {
                this.videoWishSvg = true;
            };
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Управление звуком видео коллекция I WISH
     */
    @action mediaWishVolume = () => {
        try {
            const video = document.getElementById("video");
            if (video.muted) {
                video.muted = false;
                this.videoWishVolumeSvg = false;
            } else {
                video.muted = true;
                this.videoWishVolumeSvg = true;
            }
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Воспроизведение видео коллекция I WISH
     */
    @action mediaWishMainPlay = () => {
        try {
            const video = document.getElementById("video");
            if (video.paused) {
                video.play();
                this.videoWishPlaySvg = false;
            } else {
                video.pause();
                this.videoWishPlaySvg = true;
            }
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Управление звуком видео коллекция Северное сияние
     */
    @action mediaNorthernLightsVolume = () => {
        try {
            const video = document.getElementById("video_northern_lights_main");
            if (video.muted) {
                video.muted = false;
                this.videoNorthernLightsVolumeSvg = false;
            } else {
                video.muted = true;
                this.videoNorthernLightsVolumeSvg = true;
            }
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Воспроизведение видео коллекция Северное сияние
     */
    @action mediaNorthernLightsMainPlay = () => {
        try {
            const video = document.getElementById("video_northern_lights_main");
            if (video.paused) {
                video.play();
                if (!isDesktop()) {
                    setTimeout(() => {
                        this.videoNorthernLightsMainPlayPauseFlag = false;
                    }, 3000)
                }
                this.videoNorthernLightsPlaySvg = false;
            } else {
                video.pause();
                this.videoNorthernLightsPlaySvg = true;
            }
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Убираем кнопку паузы на мобилке коллекция Северное сияние
     */
    @action mediaNorthernLightsMainPlayPause = () => {
        try {
            if (!isDesktop()) {
                if (!this.videoNorthernLightsMainPlayPauseFlag) {
                    this.videoNorthernLightsMainPlayPauseFlag = true;
                }
                setTimeout(() => {
                    this.videoNorthernLightsMainPlayPauseFlag = false;
                }, 3000)
            }
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Воспроизведение видео коллекция Северное сияние (2)
     */
    @action mediaNorthernLightsPlay = () => {
        try {
            const video = document.getElementById("video_northern_lights");

            if (video.paused) {
                video.play();
                this.videoNorthernLightsSvg = false;
            } else {
                video.pause();
                this.videoNorthernLightsSvg = true;
            }
            video.onended = (e) => {
                this.videoNorthernLightsSvg = true;
            };
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Убираем кнопку паузы на мобилке коллекция Северное сияние (2)
     */
    @action mediaNorthernLightsPlayPause = () => {
        try {
            if (!isDesktop()) {
                if (!this.videoNorthernLightsPlayPauseFlag) {
                    this.videoNorthernLightsPlayPauseFlag = true;
                }
                setTimeout(() => {
                    this.videoNorthernLightsPlayPauseFlag = false;
                }, 3000)
            }
        } catch (e) {
            console.log('e', e);
        }
    }


}
