import {configure, makeObservable, observable, action} from 'mobx';
import reachGoalYM from "@/Helpers/ReachGoalYM";
import reachGoalGA from "@/Helpers/ReachGoalGA";

/**
 * Класс для работы с избранным
 */
export default class FavouritesStore {
    // Массив избранного
    @observable favouritesData = []
    @observable favouritesDataPage = []

    // Флаг загрузчика
    @observable favouritesLoader = false

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }


    @action favouritesDataChange(favouritesData) {
        this.favouritesData = favouritesData
    }

    /**
     * Обработка добавления / удаления избранного
     */
    @action
    async favouritesChange(e, el) {
        try {
            const checked = e.target.checked;
            let url = checked ? '/favourites_add' : '/favourites_delete';

            // Стучимся на сервак
            const response = await axios.post(url, {
                product_id: el.id
            });

            const {success = {}} = response.data;
            const {status = false} = success;

            if (status) {
                if (checked) {
                    // Отправка цели
                    reachGoalYM('add_wish');

                    // Отправка цели
                    reachGoalGA('add_wish');
                }

                // Раскладываем данные
                const {favouritesData} = success;
                // Если статус положительный
                this.favouritesData = favouritesData;
            }
        } catch (e) {
            console.log('e', e);
        }
    }
}
