import {action, makeObservable, observable} from 'mobx';
import {Inertia} from "@inertiajs/inertia";
import isEmpty from "is-empty";

export default class AdminAllStore {
    //
    @observable extension = 'ВСЕ';
    //
    @observable sortFiles = 'time';
    // Путь к изображению
    @observable pathAddImage = '';
    // Название поля в форме, куда сохраним путь к изображению
    @observable field = '';
    //
    @observable typeAdd = '';
    // Префикс админ панели
    prefixAdminPanel = '';
    // Все фильтры
    @observable filters = [];
    // Пагинация списка
    @observable pagination = {'page': 1, 'perPage': 50};
    // Сортировка списка
    @observable sort = {};
    // Поисковой запрос
    @observable searchQuery = '';
    // Тело редактора
    @observable editorBody = null;
    // Элементы фильтра
    @observable chips = [];
    // Модальное окно "Профиль пользователя"
    @observable flagModalProfileMenu = false;
    // Модальное окно "Профиль пользователя"
    @observable flagModalFilters = false;
    // Событие назад в браузере
    @observable prevButton = false;

    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    /**
     * Запоминаем входящие данные
     * @param props
     */
    startMainLayout = (props) => {
        const {pagination = {}, prefixAdminPanel = '', searchQuery = '', chips = [], resultFilters = []} = props;
        const {page = 1, perPage = 50} = pagination;

        // Сбрасываем данные по фильтрации
        // if (searchQuery === '') {
        //     delete this.filters.search;
        // }

        // Заполняем поисковой запрос
        // if (this.searchQuery === '') {
        //     this.searchQuery = searchQuery;
        // }
        // this.searchQuery = searchQuery;

        // const searchParams = new URLSearchParams(window.location.search);
        // if (!searchParams.has('filter[search]')) {
        //     this.searchQuery = '';
        // }

        // // Устанавливаем данные для пагинации
        // this.pagination = {'page': page, 'perPage': perPage};


        // if (!searchParams.has('pagination[page]')) {
        //     this.pagination = {'page': page, 'perPage': perPage};
        // } else {
        //     const pageUrl = Number(searchParams.get('pagination[page]'));
        //     const perPageUrl = Number(searchParams.get('pagination[perPage]'));
        //     console.log('page', page);
        //     console.log('pageUrl', pageUrl);
        //     this.pagination = {'page': pageUrl, 'perPage': perPageUrl};
        // }

        // Запоминаем префикс админ панели
        this.prefixAdminPanel = prefixAdminPanel;

        // Запоминаем выбранные фильтры (быстрые фильтры)
        this.chips = chips;

        // Запоминаем фильтры
        if (props.hasOwnProperty('resultFilters')) {
            this.filters = resultFilters;
        }
        // if (isEmpty(this.filters) && !isEmpty(resultFilters)) {
        //     this.filters = resultFilters;
        // }
    }

    @action pathAddImageSet = (src) => {
        this.pathAddImage = src;
    };

    @action typeAddSet = (src) => {
        this.typeAdd = src;
    };

    @action clear = () => {
        this.pathAddImage = '';
        this.field = '';
        this.typeAdd = '';
    }

    /**
     * Очистим все данные по ранее добавленным изображениям
     */
    @action clearImages = () => {
        this.pathAddImage = ''; // Путь к изображению
        this.field = ''; // Название поля в форме, куда был записан путь к изображению
    }

    /**
     *
     */
        // @action parseUrl = () => {
        //     const searchParams = new URLSearchParams(window.location.search);
        //     if (!searchParams.has('filters')) {
        //         return;
        //     }
        //     let searchFilters = JSON.parse(searchParams.get('filters'));
        //     const typesFilters = ['orWhere'];
        //     let filters = searchFilters, chips = [];
        //     // typesFilters.forEach((type) => {
        //     //     if (searchFilters.hasOwnProperty(type)) {
        //     //         searchFilters[type].forEach((el) => {
        //     //             chips.push({'type': type, ...el});
        //     //         });
        //     //     }
        //     // });
        //
        //     this.filters = filters;
        //     // this.chips = chips;
        // }

        // Применяемую фильтрацию
    @action changeSearchFilter = (e, filterName) => {
        this.searchQuery = e.target.value;

        let date = new Date();
        window.flagTimeSuggestions = date.setMilliseconds(date.getMilliseconds() + 500);

        setTimeout(() => {
            if (window.flagTimeSuggestions <= new Date()) {
                // Сбрасываем пагинацию
                this.resetPagination();

                this.visit();
            }
        }, 500);

        // // Сбрасываем пагинацию
        // this.resetPagination();
        //
        // // this.filters[filterName] = filterValue;
        // //
        // // // Если значение пустое, удаляем фильтр из списка фильтров
        // // if (filterValue === '') {
        // //     delete this.filters[filterName];
        // // }
        //
        // this.visit();
    }

    /**
     * Переключаем основные фильтры участвующие в запросе
     * @param filter
     */
    @action changeFilter = (filter) => {
        // Найти индекс элемента с таким же field и type
        const index = this.filters.findIndex(f => f.field === filter.field && f.type === filter.type);

        if (filter.value === '') {
            this.filters.splice(index, 1);
        } else if (index !== -1) {
            if (filter.type === 'dateStart' || filter.type === 'dateEnd') {
                this.filters[index].values = [filter.value];
            } else {
                // Если элемент найден, проверить, есть ли значение в values
                const valueIndex = this.filters[index].values.indexOf(filter.value);

                if (valueIndex === -1) {
                    // Если значения нет, добавить его
                    this.filters[index].values.push(filter.value);
                } else {
                    // Если значение есть, удалить его
                    this.filters[index].values.splice(valueIndex, 1);

                    // Если массив values пуст, удалить элемент
                    if (this.filters[index].values.length === 0) {
                        this.filters.splice(index, 1);
                    }
                }
            }
        } else {
            // Если элемента с таким field и type нет, добавить новый
            this.filters.push({
                field: filter.field,
                operator: filter.operator ?? '=',
                type: filter.type,
                values: [filter.value],
            });
        }

        // if (filter.type === 'dateStart' || filter.type === 'dateEnd') {
        //     if (filter.value === '') {
        //         this.filters.splice(index, 1);
        //     } else if (index !== -1) {
        //         this.filters[index].values = [filter.value];
        //     } else {
        //         // Если элемента с таким field и type нет, добавить новый
        //         this.filters.push({
        //             field: filter.field,
        //             operator: filter.operator ?? '=',
        //             type: filter.type,
        //             values: [filter.value],
        //         });
        //     }
        // } else {
        //
        // }

        // if (filter.type === 'dateStart' || filter.type === 'dateEnd') {
        //     if (filter.value === '') {
        //         delete this.filters[filter.type];
        //     } else {
        //         this.filters[filter.type] = filter;
        //     }
        // } else {
        //     if (this.filters.hasOwnProperty(filter.type)) {
        //         // Поиск фильтра с таким же field
        //         let existingFilter = this.filters[filter.type].find(el => el.field === filter.field);
        //
        //         if (existingFilter) {
        //             // Если фильтр уже существует, добавляем значение в массив values, если его нет
        //             if (!existingFilter.values.includes(filter.value)) {
        //                 existingFilter.values.push(filter.value);
        //                 console.log('existingFilter', existingFilter);
        //                 // this.filters[filter.type].push(existingFilter);
        //             } else {
        //                 // Удаление значения из массива values, если оно присутствует
        //                 existingFilter.values = existingFilter.values.filter(val => val !== filter.value);
        //                 // this.filters[filter.type].push(existingFilter);
        //                 console.log('existingFilter', existingFilter);
        //
        //                 // Если массив values пустой, удаляем фильтр
        //                 if (existingFilter.values.length === 0) {
        //                     console.log('1', 1);
        //                     this.filters[filter.type].filter(filter => {
        //                         console.log('filter', filter);
        //                         console.log('existingFilter', existingFilter);
        //                         return filter.field !== existingFilter.field
        //                     });
        //                     console.log('this.filters[filter.type]', this.filters[filter.type]);
        //                 }
        //             }
        //         } else {
        //             // Если фильтра нет, создаем новый
        //             this.filters[filter.type].push({
        //                 'field': filter.field,
        //                 // 'label': filter.label,
        //                 'operator': filter.operator ?? '=',
        //                 'type': filter.type,
        //                 'values': [filter.value],
        //             });
        //         }
        //     } else {
        //         this.filters[filter.type] = [{
        //             'field': filter.field,
        //             // 'label': filter.label,
        //             'operator': filter.operator ?? '=',
        //             'type': filter.type,
        //             'values': [filter.value],
        //         }];
        //     }
        // }

        // else {
        //     if (this.filters.hasOwnProperty(filter.type)) {
        //         const index = this.filters[filter.type].findIndex(
        //             el => el.field === filter.field && el.value === filter.value
        //         );
        //
        //         if (index !== -1) {
        //             // Если элемент уже существует, удаляем его
        //             this.filters[filter.type].splice(index, 1);
        //         } else {
        //             // Если элемента нет, добавляем его
        //             // this.filters[filter.type].push(filter);
        //             this.filters[filter.type].push([
        //                 filter
        //             ]);
        //         }
        //     } else {
        //         this.filters[filter.type] = [{
        //             'field': filter.field,
        //             'label': filter.label,
        //             'operator': filter.operator ?? '=',
        //             'type': filter.type,
        //             'value': [filter.value],
        //         }];
        //     }
        // }
    }

    /**
     * Удаляем основной установленный фильтр участвующий в запросе
     * @param filter
     */
    @action deleteFilter(filter) {
        // Найти индекс элемента с таким же field и type
        const index = this.filters.findIndex(f => f.field === filter.field && f.type === filter.type);
        if (index !== -1) {
            // Если элемент найден, проверить, есть ли значение в values
            const valueIndex = this.filters[index].values.indexOf(filter.value);
            if (valueIndex !== -1) {
                // Если значение есть, удалить его
                this.filters[index].values.splice(valueIndex, 1);

                // Если массив values пуст, удалить элемент
                if (this.filters[index].values.length === 0) {
                    this.filters.splice(index, 1);
                }
            }
        }

        // if (filter.type === 'dateStart' || filter.type === 'dateEnd') {
        //     delete this.filters[filter.type];
        // } else {
        //     if (this.filters.hasOwnProperty(filter.type)) {
        //         // const index = this.filters[filter.type].findIndex(
        //         //     el => el.field === filter.field && el.value === filter.value
        //         // );
        //         //
        //         // if (index !== -1) {
        //         //     // Если элемент уже существует, удаляем его
        //         //     this.filters[filter.type].splice(index, 1);
        //         // }
        //         // Поиск фильтра с таким же field
        //         let existingFilter = this.filters[filter.type].find(el => el.field === filter.field);
        //         if (existingFilter) {
        //             // Если фильтр уже существует, добавляем значение в массив values, если его нет
        //             if (existingFilter.values.includes(filter.value)) {
        //                 // Удаление значения из массива values, если оно присутствует
        //                 existingFilter.values = existingFilter.values.filter(val => val !== filter.value);
        //
        //                 // Если массив values пустой, удаляем фильтр
        //                 if (existingFilter.values.length === 0) {
        //                     this.filters[filter.type].filter(filter => filter !== existingFilter);
        //                 }
        //             }
        //         }
        //     }
        // }

        this.visit();
    }

    /**
     * Удаляем основные фильтры участвующие в запросе
     */
    @action deleteFilters() {
        // Сбрасываем пагинацию
        this.resetPagination();
        // Очищаем фильтры
        this.filters = [];
        // Очищаем чипы
        this.deleteChips();
        // Запрос
        this.visit();
    }

    // Применяем пагинацию
    @action changePagination = (page, perPage) => {
        this.pagination = {'page': page, 'perPage': perPage};

        this.visit();
    }

    // Сбрасываем пагинацию
    @action resetPagination = () => {
        this.pagination = {'page': 1, 'perPage': 50};
    }

    // Применяем сортировку
    @action changeSort = (field) => {
        // Проверяем, содержит ли this.sort свойство 'field' и равно ли оно полю, которое передали
        if (this.sort && this.sort.field === field) {
            // Если содержит 'field' и 'type' === 'DESC', меняем 'type' на 'ASC'
            if (this.sort.type === 'DESC') {
                this.sort.type = 'ASC';
            } else {
                // Иначе, если 'type' === 'ASC', меняем на 'DESC'
                this.sort.type = 'DESC';
            }
        } else {
            // Если не содержит 'field' или 'field' !== field, устанавливаем новое значение с 'type' === 'DESC'
            this.sort = {'field': field, 'type': 'DESC'};
        }

        // Сбрасываем пагинацию
        this.resetPagination();

        this.visit();
    }

    // Делаем запрос на текущий урл с параметрами
    @action visit = () => {
        let values = {
            pagination: this.pagination,
            sort: this.sort,
        };

        if (this.searchQuery) {
            values.searchFilter = {'search': this.searchQuery};
        }

        if (this.filters.length > 0) {
            values.filters = JSON.stringify(this.filters);
        }

        Inertia.get(location.pathname, values);

        // Inertia.get(location.pathname, {
        //     searchFilter: {'search': this.searchQuery},
        //     filters: JSON.stringify(this.filters),
        //     pagination: this.pagination,
        //     // pagination: {'page': 1, 'perPage': 50},
        //     sort: this.sort,
        //     // sort: {'field': 'id', 'type': 'ASC'},
        // });
    }

    /**
     * Переключаем фильтры
     * @param newChip
     */
    @action toggleChip(newChip) {
        if (newChip.type === 'dateStart' || newChip.type === 'dateEnd') {
            if (newChip.value === '') {
                delete this.chips[newChip.type];
            } else {
                this.chips[newChip.type] = newChip;
            }
        } else {
            // Поиск существующего элемента в массиве по какому-то критерию (например, по полю 'field' и 'value')
            const index = this.chips.findIndex(
                chip => chip.field === newChip.field && chip.value === newChip.value
            );

            if (index !== -1) {
                // Если элемент уже существует, удаляем его
                this.chips.splice(index, 1);
            } else {
                // Если элемента нет, добавляем его
                this.chips.push(newChip);
            }
        }
    }

    /**
     * Удаляем 1 установленный фильтр
     * @param newChip
     */
    @action deleteChip(newChip) {
        // Поиск существующего элемента в массиве по какому-то критерию (например, по полю 'field' и 'value')
        const index = this.chips.findIndex(
            chip => chip.field === newChip.field && chip.value === newChip.value
        );

        // Если элемент уже существует, удаляем его
        if (index !== -1) {
            this.chips.splice(index, 1);
        }
    }

    /**
     * Удаляем все выбранные фильтры
     */
    @action deleteChips() {
        this.chips = [];
    }

    /**
     * Сброс всех параметров при переходах по страницам
     */
    @action resetAll() {
        this.chips = [];
        this.filters = [];
        this.pagination = {'page': 1, 'perPage': 50};
        this.searchQuery = '';
        this.sort = {};
    }

    /**
     * Открываем окно "Профиль пользователя"
     */
    @action openModalProfileMenu = () => {
        this.flagModalProfileMenu = true;
    };

    /**
     * Закрываем окно "Профиль пользователя"
     */
    @action closeModalProfileMenu = () => {
        this.flagModalProfileMenu = false;
    };

    /**
     * Открываем окно "Профиль пользователя"
     */
    @action openModalFilters = () => {
        this.flagModalFilters = true;
    };

    /**
     * Закрываем окно "Профиль пользователя"
     */
    @action closeModalFilters = () => {
        this.flagModalFilters = false;
    };

    /**
     * Применяем фильтрацию
     * @param el
     */
    @action applyFiltering = (el) => {
        // Сбрасываем пагинацию
        this.resetPagination();
        // Изменяем чипы (выбранные фильтры)
        this.toggleChip(el);
        // Изменяем основные фильтры участвующие в запросе
        this.changeFilter(el);
        // Запрашиваем данные
        this.visit();
    };
}
