import {action, configure, makeObservable, observable} from 'mobx';
import isEmpty from "is-empty";
import {Inertia} from "@inertiajs/inertia";

export default class NewsStore {
    // Определяем активный элемент в навигации
    @observable selected = 1;
    @observable atrCategory = 1;

    // Все категории блога
    @observable newsCategories = [];
    // Все статьи блога
    @observable news = [];
    // Отфильтрованные статьи блога
    @observable newsResult = [];
    // Детальная статья блога
    @observable newsArticle = {};
    // Статьи блога из той же категории
    @observable newsFromSameCategory = [];
    // Количество новостей
    @observable countNews = 0;
    // ID активной категории в блоге
    @observable categoryId = 1;
    // Предыдущая статья
    @observable prev = '';
    // Следующая статья
    @observable next = '';
    // Лоадеры
    @observable newsLoader = false;

    // Пагинация
    // Активная страница
    @observable activePage = 1;
    // Сколько показывать на странице
    @observable listCount = 12;
    listShowCount = 40;
    // Сколько показывать товаров на странице активный
    @observable listCountActive = 0;
    // Сколько пропустить моделей перед выборкой
    @observable pageNumberMiss = 1;
    @observable itemsArray = [];

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }

    /**
     * Все по блогу getNewsData
     */
    @action getNewsData = (newsData = []) => {

        if (newsData.categoryId === null) {
            this.categoryId = 1;
        }

        // if (newsData.newsCategories.length !== 0) {
            this.newsCategories = newsData.newsCategories;
        // }

        this.news = newsData.news;
        this.newsResult = this.news;

        this.handlePageChange();
        if (newsData.countNews !== 0) {
            this.countNews = newsData.countNews;
        }
    }

    /**
     * Пагинация
     * @param pageNumber
     */
    @action handlePageChange = (pageNumber) => {
        try {
            const data = this.news;

            if (!isEmpty(pageNumber)) {
                this.activePage = pageNumber;
            }

            this.listCountActive = this.listCount * this.activePage;
            this.pageNumberMiss = this.listCountActive - this.listCount;

            this.newsResult = data.slice(this.pageNumberMiss, this.listCountActive);
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Навигация по категориям
     * @param el
     * @param key
     */
    @action onNewsNavigation = (el, key) => {
        try {
            // Скидываем активную страницу при изменении категории
            if (this.activePage !== 1) {
                this.activePage = 1;
            }
            // Добавляем класс
            let elements = document.querySelectorAll('.js-news-chip');
            elements.forEach((el) => el.classList.remove('active-news-chip'));
            el.currentTarget.classList.add('active-news-chip');

            this.categoryId = key;

            this.newsDataSend();
        } catch (e) {
            console.log('e', e);
        }
    };

    @action newsDataSend = () => {
        Inertia.post(
            '/news',
            {
                categoryId: this.categoryId,
            },
            {
                preserveScroll: true,
            }
        );
    }

    @action cardAdd(cards) {
        this.itemsArrayResult = cards;
    }
}
